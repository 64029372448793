<template>
  <div id="video-box">
      <div id="videos">
        
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import Session from './Session.vue';
import Vue from 'vue';
import {Cache} from 'aws-amplify';
import {components} from 'aws-amplify-vue';


export default {
  name: 'VideoComponent',
  components: {
    Cache,
    components
  },
  props: {
    roomName: {
      type: String,
      default: "Def_Room"
    }
  },
  data: function(){
    return{
      scriptLoaded: false,
      apiKey: null,
      sessionId: null,
      token: null
    }
  },
  created() {
      this.getVideoConfig();
  },
  methods:{
    getVideoConfig:function (){
      this.apiKey = Cache.getItem("api_key")
      this.sessionId = Cache.getItem("session")
      this.token = Cache.getItem("token")
      console.log(Cache.getItem("api_key"))
    },

      
  },
  mounted(){
    
      this.scriptLoaded = true
      var apiKey = this.apiKey
      var sessionId = this.sessionId
      var token = this.token
      
      const createSession = (apiKey, sessionId, token) => {
        new Vue({
          el: '#videos',
          render: h => h(Session, {
            props: {
              sessionId,
              apiKey,
              token
            }
          })
        });
      };
      createSession(apiKey, sessionId, token);
      }

      }
</script>

<style>

</style>