<template>
  <div id="session" @error="errorHandler">
    <div class="row">
      <div class="column" id="subscriber-col">
        <div class="card" id="subscribers" v-for="stream in streams" :key="stream.streamId">
          <subscriber @error="errorHandler" :opts="{insertMode: 'append', height:'26.2rem',width: '35rem',showControls: true, name:'对方视频', fitMode:'contain'}"
            :stream="stream" :session="session"></subscriber>
        </div>
      </div>
      

      <div class="column">
        <div class="card" id="publisher">
          <publisher :session="session" :opts="{insertMode: 'append' , height: '10rem', width: '13.1rem',showControls: true, fitMode:'contain', name:'我的视频'}"
            @error="errorHandler"></publisher>
        </div>
      </div>



  </div>
  </div>
</template>

<script>
import OT from '@opentok/client';
import Publisher from './Publisher.vue';
import Subscriber from './Subscriber.vue';

const errorHandler = (err) => {
  alert(err.message);
};

export default {
  name: 'session',
  components: { Publisher, Subscriber },
  props: {
    sessionId: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    },
  },
  created () {
    this.session = OT.initSession(this.apiKey, this.sessionId);
    this.session.connect(this.token, (err) => {
      if (err) {
        errorHandler(err);
      }
    });
    this.session.on('streamCreated', (event) => {
      this.streams.push(event.stream);
    });
    this.session.on('streamDestroyed', (event) => {
      const idx = this.streams.indexOf(event.stream);
      if (idx > -1) {
        this.streams.splice(idx, 1);
      } 
    });
  },
  data: () => ({
    streams: [],
    session: null,
  }),
  methods: {
    errorHandler
  },
    beforeRouteLeave (to, from, next) {
      console.log("leaving")
      console.log("leaving",to,from,next)
      next()
  }

};
</script>

<style>

  .OT_subscriber {
    /* float: right; */
    
  }
  .OT_publisher {
    /* float: left; */
  }

  * {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

/* Float four columns side by side */
.column {
  float: left;
  
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding in columns */
.row {
  margin: 2rem 5%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}

/* Responsive columns - one column layout (vertical) on small screens */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
  .row{
    margin: 2rem 5%;
  }
}
</style>
